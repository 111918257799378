
<template>
  <div class="login">
    <BaseHeaderBar
      :title="title"
      :showCustomerService="false"
      :showBackButton="true"
    />
    <img class="login-icon" src="@/assets/img/login-icon.png" />
    <form class="login-content" @submit.prevent="onSubmit">
      <div class="input-block">
        <input
          v-model.trim="phone"
          type="tel"
          maxlength="13"
          autofocus
          placeholder="Masukkan nomor handphone dengan awalan 08"
        />
      </div>
      <div v-if="showPasswordInput" class="input-block">
        <input
          class="password"
          v-model.trim="password"
          maxlength="8"
          type="tel"
          :placeholder="tipText"
        />
      </div>
      <div class="input-block">
        <input
          v-model.trim="verifyCode"
          type="tel"
          maxlength="4"
          placeholder="Masukkan 4 digit angka kode verifikasi"
        />
        <div class="verify-code-btn" v-if="isGetCode" @click="getVerifyCode">
          Dapatkan kode verifikasi
        </div>
        <div class="verify-code-btn count-down" v-else>{{ countDown }}s</div>
      </div>
      <button
        type="submit"
        :class="['confirm-btn', showPasswordInput ? 'near' : 'far']"
      >
        {{ buttonText }}
      </button>
    </form>

    <van-dialog
      v-model="visible"
      :showConfirmButton="false"
      close-on-click-overlay
      class="verify-img-dialog"
    >
      <h3 class="verify-title">Diperlukan verifikasi</h3>
      <div class="verify-body">
        <div class="verify-img-block">
          <div class="verify-img">
            <img ref="verifyImg" />
          </div>
          <div class="refresh-btn" @click="refreshVerifyCode">
            <img src="@/assets/img/refresh-icon.png" alt="refresh" />
          </div>
        </div>
        <div class="verify-input-box">
          <div v-for="(item, index) in verifyImgCodes" :key="index">
            <input
              :ref="`input${index}`"
              v-model="item.value"
              type="tel"
              @input="onInput(index)"
              @keyup.delete="onDelete(index)"
              maxlength="1"
            />
          </div>
          <p class="error-info">{{ errorInfo }}</p>
        </div>
        <button type="button" class="verify-confirm-btn" @click="onVerify">
          Yakin
        </button>
      </div>
      <img
        class="close-icon"
        src="@/assets/img/verify-close-icon.png"
        alt="close"
        @click="onCloseDialog()"
      />
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast, Dialog } from 'vant'
import request from '@/utils/request'
import { validatePhoneNumber, validatePassword, validateVerifyCode } from '@/utils/validator'
import dialogVisible from '@/mixins/dialogVisible'
import { baseURL } from '@/config'

Vue.use(Toast)
const maxTime = 150
let timer
const clearTimer = () => {
  timer && clearTimeout(timer) && (timer = null)
}
const titleMap = {
  forgetPassword: 'Lupa Password',
  messageLogin: 'Login dengan SMS',
  register: 'Daftar'
}
const buttonText = {
  forgetPassword: 'Yakin',
  messageLogin: 'Login',
  register: 'Daftar'
}
const apiMap = {
  forgetPassword: 'usdrerForgetPassword',
  messageLogin: 'usdrerLogin',
  register: 'usdrerRegistration'
}

const messageTypeMap = {
  forgetPassword: 3,
  messageLogin: 2,
  register: 1
}

export default {
  name: 'LoginForm',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  mixins: [dialogVisible],
  data () {
    return {
      phone: '',
      password: '',
      verifyCode: '',
      isGetCode: true,
      countDown: maxTime,
      verifyImg: '',
      verifyImgCodes: [
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        }
      ],
      errorInfo: ''
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  computed: {
    title () {
      return titleMap[this.type]
    },
    buttonText () {
      return buttonText[this.type]
    },
    showPasswordInput () {
      return this.type === 'forgetPassword' || this.type === 'register'
    },
    tipText () {
      if (this.type === 'register') {
        return 'Atur 8 digit password'
      } else {
        return 'Masukkan 8 digit angka password'
      }
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.$nextTick(() => {
          this.getImgVerifyCode()
          this.$refs.input0[0].focus()
        })
      }
    }
  },
  methods: {
    validatePhoneNumber () {
      if (!this.phone) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!validatePhoneNumber(this.phone)) {
        Toast('Nomor handphone salah')
        return false
      }
      return true
    },
    validate () {
      if (!this.validatePhoneNumber()) return false
      if (this.showPasswordInput) {
        if (!this.password) {
          Toast(this.tipText)
          return false
        }
        if (!validatePassword(this.password)) {
          Toast('Password salah')
          return false
        }
      }
      if (!this.verifyCode) {
        Toast('Masukkan 4 digit angka kode verifikasi')
        return false
      }
      if (!validateVerifyCode(this.verifyCode)) {
        Toast('Kode verifikasi salah')
        return false
      }
      return true
    },
    startCountDown () {
      if (this.countDown <= 0) {
        this.reset()
      } else {
        timer = setTimeout(() => {
          this.countDown--
          this.startCountDown()
        }, 1000)
      }
    },
    getVerifyCode () {
      if (!this.validatePhoneNumber()) return
      const postData = {
        usdrerPhone: this.phone,
        tydrpe: messageTypeMap[this.type],
        smdrsType: 1
      }
      this.imgVerifyCode && (postData.imdrgCode = this.imgVerifyCode)
      request.post('usdrersendMessages', postData)
        .then(() => {
          Toast('Kode verifikasi terkirim. Silakan cek')
          this.countDown = maxTime
          this.isGetCode = false
          this.startCountDown()
        })
        .catch(err => {
          if (err.code === 504) {
            this.visible = true
          }
          this.reset()
        })
    },
    getImgVerifyCode () {
      this.$refs.verifyImg.src = `${baseURL}usdrergetGraphic?usdrerPhone=${this.phone}&t=${+Date.now()}`
    },
    reset () {
      clearTimer()
      this.isGetCode = true
      this.countDown = maxTime
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        usdrerPhone: this.phone,
        smdrs_Verificationcode: this.verifyCode,
        smdrsType: 1,
        apdrpVersion: '1.0'
      }
      if (this.type === 'forgetPassword') {
        postData.usdrerPassword = this.password
      } else if (this.type === 'messageLogin') {
        postData.lodrginType = 2
      } else if (this.type === 'register') {
        postData.enhedensdige = sessionStorage.getItem('enhedensdige')
        postData.dedrviceNo = sessionStorage.getItem('enhedensdige')
        postData.apitype = sessionStorage.getItem('apitype') || 1
        postData.usdrerPassword = this.password
        postData.prdromotionChannel = 'AppStore'
      }
      request.post(apiMap[this.type], postData)
        .then(res => {
          if (this.type === 'forgetPassword') {
            const { query } = this.$route
            this.$router.push({
              path: '/login',
              query
            })
          } else if (this.type === 'register' || this.type === 'messageLogin') {
            const form = {
              userIdBK: res.penggunaID,
              tokenBK: res.penggunaToken,
              versionBK: '1.0',
              deviceNoBK: sessionStorage.getItem('enhedensdige')
            }
            this.addressBook()
            this.$store.commit('recordInfo', {
              type: 'userBKInfo',
              value: form
            })
            localStorage.setItem('userBKInfo', JSON.stringify(form))
            this.$router.push('/home1')
          }
        }).catch(() => {
          this.verifyCode = ''
        }).finally(() => {
          this.flag = false
        })
    },
    onVerify () {
      this.imgVerifyCode = ''
      this.verifyImgCodes.forEach(item => {
        this.imgVerifyCode = this.imgVerifyCode + item.value
      })
      const postData = {
        usdrerPhone: this.phone,
        grdraphicCode: this.imgVerifyCode
      }
      request.post('usdrercheckGraphic', postData)
        .then(res => {
          Toast('Verifikasi Berhasil')
          this.visible = false
          this.getVerifyCode()
        }).catch(() => {
          this.verifyImgCodes.forEach(item => {
            item.value = ''
          })
          this.getImgVerifyCode()
        })
    },
    refreshVerifyCode () {
      this.getImgVerifyCode()
    },
    onInput (index) {
      if (this.verifyImgCodes[index].value && index < 3) {
        console.log('onInput', index)
        this.$refs[`input${index + 1}`][0].focus()
      }
    },
    onDelete (index) {
      if (index > 0) {
        this.$refs[`input${index - 1}`][0].focus()
        console.log('onDelete', index)
      }
    }
  },
  deactivated () {
    this.reset()
  }
}
</script>

<style scoped lang="less">
.login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.login-icon {
  width: 470px;
  height: 356px;
  padding-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.login-content {
  flex: 1;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 42px;
}

.input-block {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #b3b3b3;

  &:nth-child(n + 2) {
    margin-top: 26px;
  }

  input {
    flex: 1;
    width: 100%;
    height: 78px;
    line-height: 78px;
    padding: 0;
    border: none;
    font-size: 30px;
    color: #333;
    background-color: transparent;

    &::placeholder {
      color: #808080;
      font-size: 26px;
    }

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

.verify-code-btn {
  width: 232px;
  height: 88px;
  border-width: 5px 4px;
  border-style: solid;
  border-color: #242d3c;
  box-sizing: border-box;
  margin-left: 10px;
  font-size: 26px;
  color: #4d4d4d;
  text-align: center;
}

.count-down {
  line-height: 88px;
}

.confirm-btn {
  .login-submit-btn;
  width: 100%;
  margin-top: 62px;
}

.verify-title {
  line-height: 42px;
  padding-top: 48px;
  font-size: 30px;
  color: #000;
  text-align: center;
}

.verify-body {
  margin-left: 72px;
  margin-right: 72px;
}

.verify-img-block {
  display: flex;
  align-items: center;
  margin-top: 74px;
  height: 144px;
}

.verify-img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 2px solid #b3b3b3;
  border-right: none;
  border-radius: 8px 0 0 8px;
  box-sizing: border-box;

  img {
    height: 100%;
  }
}

.refresh-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78px;
  height: 100%;
  background-color: @theme-color;
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
  }
}

.verify-input-box {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  div {
    flex: 1;

    &:nth-child(n + 2) {
      margin-left: 64px;
    }
  }

  input {
    width: 100%;
    line-height: 88px;
    padding: 0;
    border: 2px solid #b3b3b3;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 30px;
    color: #333;
    text-align: center;
  }

  .error-info {
    margin-top: 8px;
    font-size: 24px;
    color: #e0383e;
  }
}

.verify-confirm-btn {
  .login-submit-btn;
  width: 546px;
  margin-top: 42px;
  margin-bottom: 38px;
}

.close-icon {
  position: absolute;
  top: 25px;
  right: 24px;
  width: 30px;
  height: 30px;
}
.password {
  -webkit-text-security: disc;
}
</style>
